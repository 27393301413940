import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import Menu from './Components/menu/menu';
import BeerMenu from './Components/beer-menu/beer-menu';
import SlideShow from './Components/slide-show/slide-show';
import * as serviceWorker from './serviceWorker';

// ReactDOM.render(<App />, document.getElementById('root'));

ReactDOM.render(<SlideShow />, document.getElementById('slide-show'));
ReactDOM.render(<Menu />, document.getElementById('menu'));
ReactDOM.render(<BeerMenu />, document.getElementById('beer-menu'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
