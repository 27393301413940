import React from 'react';
import $ from 'jquery';
import { CSSGlobalVariables } from 'css-global-variables';

let cssVar = new CSSGlobalVariables();
let curIndex2 = 0; //current index starts at 0 for spinner

function spin2() {
  let maxIndex2 = $('.ferrisWrap2 .beerWrap').length;  //max index is set to length of array

  $('.ferrisWrap2').animate({ scrollTop: curIndex2 * $('.ferrisWrap2 .beerWrap').height() + 'px' }, 5000, function () {
    if (curIndex2 + 1 === maxIndex2) {
      curIndex2 = 0;
    } else {
      curIndex2++;
    }
    setTimeout(function () { spin2() }, 10);
  });
}

export default class BeerMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {
    fetch(`https://business.untappd.com/api/v1/sections/209144/items`, {
      headers: { 'Authorization': 'Basic cmtub3g3MjRAZ21haWwuY29tOk5hZ2doUlVfTHNxY3dYTk56b3R3' }
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.items
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const { error, isLoaded, items } = this.state;
    cssVar.beerCount = items.length;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      $(function () {
        spin2();
      });
      return (
        <div className="ferrisWrap2">
          {items.map(item => (
            <div key={item.id} className="beerWrap">
              <img className="beerLabelImg" src={item.label_image_hd} alt=""></img>
              <div className="beerInfo">
                <h2 className="beerName">{item.name}</h2>
                <div className="beerSubwrap1">
                  <p className="beerBrewer">{item.original_brewery}</p>
                  <p className="spacer">|</p>
                  <p className="beerLocation">{item.brewery_location}</p>
                </div>
                <div className="beerSubwrap2">
                  <p className="beerStyle">{item.style}</p>
                  <p className="spacer">|</p>
                  <p className="beerABV">{item.abv}%</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }
  }
}