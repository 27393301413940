import React from 'react';

export default class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      data: []
    };
  }

  componentDidMount() {
    fetch(`https://business.untappd.com/api/v1/custom_menus/16946?full=true`, {
      headers: { 'Authorization': 'Basic cmtub3g3MjRAZ21haWwuY29tOk5hZ2doUlVfTHNxY3dYTk56b3R3' }
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result.custom_menu.custom_sections
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {

    function hasSubItems(props) {
      const subItems = props.custom_containers.length
      if (subItems === 1) {
        return (
          <div className="noOptionsWrap">
            <h2 className="noOptions">{props.name}</h2>
            <h2>|</h2>
            <p>{props.custom_containers[0].price}</p>
          </div>
        )
      } else {
        return (
          <div key={props.id} className="optionWrap">
            <h2 className="optionName">{props.name} :</h2>
            <ul>
              {props.custom_containers.map(detail => (
                <li key={detail.id}>
                  <p>{detail.name}</p>
                  <h2>|</h2>
                  <p>{detail.price}</p>
                </li>
              ))}
            </ul>
          </div>
        )
      }
    }

    const { error, isLoaded, data } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        data.map(section => (
          <div key={section.id} className="sectionWrap">
            <h1 className="sectionName">{section.name}</h1>
            <div className="itemsWrap">
              {section.custom_items.map(item => (
                <div key={item.id} className="itemDetailsWrap">
                  {hasSubItems(item)}
                </div>
              ))}
            </div>
          </div>
        ))
      );
    }
  }
}