import React from 'react';
import $ from 'jquery';

let curIndex = 0; //current index starts at 0 for spinner

function spin() {
  let maxIndex = $('.ferrisWrap div').length; //max index is set to length of array
  
  $('.ferrisWrap').animate({ scrollTop: curIndex * $('.ferrisWrap div').height() + 'px' }, 4000, function () {
    if (curIndex + 1 === maxIndex) {
      curIndex = 0;
    } else {
      curIndex++;
    }
    setTimeout(function () { spin() }, 0);
  });
}

export default class SlideShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      data: []
    };
  }

  componentDidMount() {
    fetch(`https://www.instagram.com/luckydogbeulah/?__a=1`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result.graphql.user.edge_owner_to_timeline_media.edges
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const { error, isLoaded, data } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      $(function(){
        spin();
      });
      return (
        <div className="ferrisWrap">
          {data.map(photo => (
            <div key={photo.node.id} className="imgWrap">
              <img className="igImg" src={photo.node.display_url} alt=""></img>
            </div>
          ))}
        </div>
      );
    }
  }
}
